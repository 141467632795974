var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        color: _vm.color,
        viewBox: "0 0 19 19",
        fill: _vm.color,
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M1 1.00293L17.5268 17.5297",
          stroke: _vm.stroke,
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M11.5171 7.26742V3.25592C11.5177 2.69704 11.3105 2.15788 10.9359 1.74312C10.5613 1.32836 10.046 1.06758 9.48994 1.01141C8.93388 0.955248 8.37679 1.1077 7.92682 1.43917C7.47684 1.77064 7.16608 2.25748 7.05487 2.80519M7.0098 7.01201V9.26566C7.01019 9.7111 7.14257 10.1464 7.39024 10.5167C7.6379 10.8869 7.98972 11.1755 8.40128 11.3459C8.81284 11.5163 9.26566 11.5609 9.70257 11.4741C10.1395 11.3873 10.5409 11.173 10.856 10.8582L7.0098 7.01201Z",
          stroke: _vm.stroke,
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M14.5219 7.76367V9.26611C14.5217 9.57594 14.494 9.88514 14.4393 10.1901M13.0195 12.9846C12.2862 13.7332 11.3459 14.2455 10.3193 14.456C9.29278 14.6664 8.22676 14.5654 7.25804 14.1658C6.28932 13.7662 5.46208 13.0862 4.88245 12.2132C4.30282 11.3402 3.99724 10.314 4.0049 9.26611V7.76367L13.0195 12.9846Z",
          stroke: _vm.stroke,
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M9.2634 14.5244V17.5293",
          troke: _vm.stroke,
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M6.25864 17.5293H12.2684",
          stroke: _vm.stroke,
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }