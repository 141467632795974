var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 199 79",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { opacity: "0.8" } }, [
        _c("path", {
          attrs: {
            d: "M75.0058 39.5749C75.0058 51.3071 75.0058 63.0202 75.0058 74.7524C75.0058 77.0336 73.8193 78.4714 71.7334 78.7398C70.1068 78.9698 68.3654 77.7621 68.021 76.1518C67.9061 75.6534 67.8487 75.1358 67.8487 74.6374C67.8487 51.1921 67.8487 27.7277 67.8296 4.28244C67.8296 2.69131 68.1932 1.27271 69.6858 0.467556C72.1927 -0.855193 74.7953 0.75511 74.9867 3.70733C75.0058 3.99489 74.9867 4.28244 74.9867 4.57C75.0058 16.2447 75.0058 27.9002 75.0058 39.5749Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M22.6099 39.1726C22.6099 28.7248 22.6099 18.277 22.6099 7.82915C22.6099 5.52872 23.7772 4.11012 25.8631 3.82256C27.5471 3.59252 29.2885 4.80025 29.6138 6.46806C29.7287 7.00483 29.7669 7.5416 29.7669 8.07836C29.7669 28.9356 29.7669 49.7929 29.7861 70.6502C29.7861 72.2221 29.4799 73.6982 27.9872 74.5034C25.3655 75.922 22.6864 74.1775 22.629 71.0719C22.5907 69.3849 22.629 67.6788 22.629 65.9918C22.6099 57.0584 22.6099 48.1251 22.6099 39.1726Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M56.5582 39.2106C56.5582 32.5393 56.5582 25.8681 56.5582 19.1968C56.5582 17.7015 56.9409 16.3979 58.2996 15.612C60.8639 14.1359 63.6004 15.8612 63.6961 18.9668C63.7153 19.8294 63.6961 20.6921 63.6961 21.5547C63.6961 34.188 63.6961 46.8403 63.6961 59.4735C63.6961 61.2372 63.1412 62.675 61.4189 63.4226C59.3139 64.3236 56.9409 62.9242 56.6539 60.6429C56.5965 60.1445 56.5391 59.6269 56.5391 59.1285C56.5582 52.4956 56.5582 45.8435 56.5582 39.2106Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M18.4381 39.4411C18.4381 44.9238 18.4381 50.4257 18.4381 55.9084C18.4381 57.3462 18.0554 58.5539 16.7732 59.3207C14.2855 60.8352 11.4916 59.2057 11.3193 56.1768C11.3193 56.0426 11.3193 55.9276 11.3193 55.7934C11.3193 44.7705 11.3193 33.7667 11.3193 22.7438C11.3193 20.5201 12.7546 18.9289 14.8022 18.8331C16.5245 18.7564 18.1894 20.1558 18.3807 21.8812C18.4381 22.3604 18.4381 22.8397 18.4381 23.3189C18.4381 28.725 18.4381 34.0735 18.4381 39.4411Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M41.0767 39.556C41.0767 43.7351 41.0767 47.9142 41.0767 52.1125C41.0767 53.6078 40.6366 54.8922 39.2587 55.659C36.6944 57.0968 33.9579 55.2564 33.9196 52.0742C33.9005 48.8535 33.9196 45.6138 33.9196 42.3932C33.9196 37.1213 33.9005 31.8495 33.9196 26.5777C33.9196 23.7405 36.2352 22.0152 38.7803 22.8778C40.1964 23.3571 41.0576 24.7949 41.0576 26.8077C41.0767 31.0635 41.0767 35.3002 41.0767 39.556Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M79.1965 39.2682C79.1965 34.9932 79.1774 30.6991 79.1965 26.4241C79.2156 23.6828 81.4355 22.0725 83.9615 22.8968C85.3967 23.3569 86.2962 24.833 86.2962 26.7884C86.2962 32.6736 86.2962 38.5397 86.2962 44.425C86.2962 47.1089 86.3153 49.7927 86.2962 52.4765C86.2579 55.122 83.8849 56.7898 81.3972 55.8697C80.0959 55.3904 79.3496 54.3744 79.2539 52.9941C79.1582 51.5947 79.1965 50.1953 79.1965 48.7767C79.1965 45.5944 79.1965 42.4313 79.1965 39.2682Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M52.4052 39.4979C52.4052 41.2616 52.4435 43.0061 52.3861 44.7698C52.3095 47.5495 50.0705 49.1789 47.5063 48.3546C46.2432 47.952 45.229 46.5143 45.2099 44.8081C45.1525 41.1274 45.1525 37.4659 45.229 33.7852C45.2481 32.2516 46.0327 31.0438 47.5254 30.4496C48.8267 29.932 50.0323 30.1812 51.0848 31.063C52.0607 31.8873 52.3861 32.9992 52.3861 34.2261C52.4243 35.9706 52.4052 37.7343 52.4052 39.4979Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M97.606 39.5176C97.606 41.3004 97.606 43.0832 97.606 44.8852C97.5868 46.6106 96.4386 48.125 94.8886 48.5276C93.4151 48.911 91.7311 48.1825 90.9465 46.7064C90.6403 46.1121 90.5063 45.3645 90.5063 44.6935C90.468 41.0895 90.468 37.4855 90.4872 33.8815C90.5063 32.1178 91.6162 30.6992 93.2237 30.3158C94.7929 29.9324 96.4195 30.6801 97.1658 32.252C97.4529 32.8655 97.5486 33.5939 97.5677 34.2841C97.6443 36.0094 97.606 37.7731 97.606 39.5176Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M7.10923 39.556C7.10923 41.4347 7.18577 43.3325 7.09009 45.2112C6.97527 47.6267 4.81284 49.0836 2.51646 48.3935C1.06208 47.9526 0.0478414 46.6298 0.0287048 44.9237C-0.00956827 41.1471 -0.00956827 37.3897 0.0287048 33.6132C0.0478414 31.7153 1.61704 30.2584 3.56897 30.1817C5.23384 30.105 6.91786 31.5045 7.03268 33.3256C7.16664 35.396 7.07095 37.4664 7.07095 39.556C7.09009 39.556 7.09009 39.556 7.10923 39.556Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M176.391 39.5749C176.391 51.3071 176.391 63.0202 176.391 74.7524C176.391 77.0336 175.204 78.4714 173.118 78.7398C171.492 78.9698 169.75 77.7621 169.406 76.1518C169.291 75.6534 169.233 75.1358 169.233 74.6374C169.233 51.1921 169.233 27.7277 169.214 4.28244C169.214 2.69131 169.578 1.27271 171.071 0.467556C173.577 -0.855193 176.18 0.75511 176.371 3.70733C176.391 3.99489 176.371 4.28244 176.371 4.57C176.391 16.2447 176.391 27.9002 176.391 39.5749Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M123.995 39.1726C123.995 28.7248 123.995 18.277 123.995 7.82915C123.995 5.52872 125.162 4.11012 127.248 3.82256C128.932 3.59252 130.673 4.80025 130.999 6.46806C131.113 7.00483 131.152 7.5416 131.152 8.07836C131.152 28.9356 131.152 49.7929 131.171 70.6502C131.171 72.2221 130.865 73.6982 129.372 74.5034C126.75 75.922 124.071 74.1775 124.014 71.0719C123.975 69.3849 124.014 67.6788 124.014 65.9918C123.995 57.0584 123.995 48.1251 123.995 39.1726Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M157.943 39.2106C157.943 32.5393 157.943 25.8681 157.943 19.1968C157.943 17.7015 158.326 16.3979 159.684 15.612C162.249 14.1359 164.985 15.8612 165.081 18.9668C165.1 19.8294 165.081 20.6921 165.081 21.5547C165.081 34.188 165.081 46.8403 165.081 59.4735C165.081 61.2372 164.526 62.675 162.804 63.4226C160.699 64.3236 158.326 62.9242 158.039 60.6429C157.981 60.1445 157.924 59.6269 157.924 59.1285C157.943 52.4956 157.943 45.8435 157.943 39.2106Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M119.823 39.4411C119.823 44.9238 119.823 50.4257 119.823 55.9084C119.823 57.3462 119.44 58.5539 118.158 59.3207C115.67 60.8352 112.876 59.2057 112.704 56.1768C112.704 56.0426 112.704 55.9276 112.704 55.7934C112.704 44.7705 112.704 33.7667 112.704 22.7438C112.704 20.5201 114.139 18.9289 116.187 18.8331C117.909 18.7564 119.574 20.1558 119.765 21.8812C119.823 22.3604 119.823 22.8397 119.823 23.3189C119.823 28.725 119.823 34.0735 119.823 39.4411Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M142.461 39.556C142.461 43.7351 142.461 47.9142 142.461 52.1125C142.461 53.6078 142.021 54.8922 140.643 55.659C138.079 57.0968 135.343 55.2564 135.304 52.0742C135.285 48.8535 135.304 45.6138 135.304 42.3932C135.304 37.1213 135.285 31.8495 135.304 26.5777C135.304 23.7405 137.62 22.0152 140.165 22.8778C141.581 23.3571 142.442 24.7949 142.442 26.8077C142.461 31.0635 142.461 35.3002 142.461 39.556Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M180.581 39.2682C180.581 34.9932 180.562 30.6991 180.581 26.4241C180.6 23.6828 182.82 22.0725 185.346 22.8968C186.782 23.3569 187.681 24.833 187.681 26.7884C187.681 32.6736 187.681 38.5397 187.681 44.425C187.681 47.1089 187.7 49.7927 187.681 52.4765C187.643 55.122 185.27 56.7898 182.782 55.8697C181.481 55.3904 180.734 54.3744 180.639 52.9941C180.543 51.5947 180.581 50.1953 180.581 48.7767C180.581 45.5944 180.581 42.4313 180.581 39.2682Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M153.79 39.4979C153.79 41.2616 153.828 43.0061 153.771 44.7698C153.694 47.5495 151.455 49.1789 148.891 48.3546C147.628 47.952 146.614 46.5143 146.595 44.8081C146.537 41.1274 146.537 37.4659 146.614 33.7852C146.633 32.2516 147.418 31.0438 148.91 30.4496C150.211 29.932 151.417 30.1812 152.47 31.063C153.446 31.8873 153.771 32.9992 153.771 34.2261C153.809 35.9706 153.79 37.7343 153.79 39.4979Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M198.991 39.5176C198.991 41.3004 198.991 43.0832 198.991 44.8852C198.972 46.6106 197.823 48.125 196.273 48.5276C194.8 48.911 193.116 48.1825 192.331 46.7064C192.025 46.1121 191.891 45.3645 191.891 44.6935C191.853 41.0895 191.853 37.4855 191.872 33.8815C191.891 32.1178 193.001 30.6992 194.608 30.3158C196.178 29.9324 197.804 30.6801 198.551 32.252C198.838 32.8655 198.933 33.5939 198.952 34.2841C199.029 36.0094 198.991 37.7731 198.991 39.5176Z",
            fill: "#E6ECF1",
          },
        }),
        _c("path", {
          attrs: {
            d: "M108.494 39.556C108.494 41.4347 108.571 43.3325 108.475 45.2112C108.36 47.6267 106.198 49.0836 103.901 48.3935C102.447 47.9526 101.433 46.6298 101.413 44.9237C101.375 41.1471 101.375 37.3897 101.413 33.6132C101.433 31.7153 103.002 30.2584 104.954 30.1817C106.619 30.105 108.303 31.5045 108.417 33.3256C108.551 35.396 108.456 37.4664 108.456 39.556C108.475 39.556 108.475 39.556 108.494 39.556Z",
            fill: "#E6ECF1",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }