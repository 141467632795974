var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        color: _vm.color,
        viewBox: "0 0 13 19",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M6.25965 1C5.66182 1 5.08847 1.23749 4.66574 1.66022C4.24301 2.08295 4.00552 2.6563 4.00552 3.25413V9.26515C4.00552 9.86298 4.24301 10.4363 4.66574 10.8591C5.08847 11.2818 5.66182 11.5193 6.25965 11.5193C6.85749 11.5193 7.43084 11.2818 7.85357 10.8591C8.2763 10.4363 8.51379 9.86298 8.51379 9.26515V3.25413C8.51379 2.6563 8.2763 2.08295 7.85357 1.66022C7.43084 1.23749 6.85749 1 6.25965 1V1Z",
          stroke: _vm.stroke,
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M11.5193 7.7627V9.26545C11.5193 10.6604 10.9651 11.9982 9.97877 12.9846C8.99239 13.971 7.65458 14.5251 6.25964 14.5251C4.8647 14.5251 3.52689 13.971 2.54051 12.9846C1.55414 11.9982 1 10.6604 1 9.26545V7.7627",
          stroke: _vm.stroke,
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M6.25961 14.5254V17.5309",
          stroke: _vm.stroke,
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M3.25409 17.5303H9.26511",
          stroke: _vm.stroke,
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }