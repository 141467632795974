<template>
  <div class="custom-select" :tabindex="tabindex" @blur="onBlur">
    <div class="selected" :class="{ open: open }" @click="onClickSelect">
      {{ selected ? selected.text : '' }}
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div v-for="(option, i) of options" :key="i" @click="onInputSelected(option)">
        {{ option.text }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    default: {
      type: Object,
      required: false,
      default: null
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      selected: {},
      open: false
    }
  },
  watch: {
    options() {
      if (this.options && this.options.length > 0) {
        this.selected = this.default ? this.default : this.options[0]
      }
    },
    default() {
      if (this.default) {
        this.selected = this.default
      }
    },
    open() {
      if (!this.open) {
        this.$emit('input-blur', this.selected)
      }
    }
  },
  mounted() {
    this.selectDefaultDevice()
  },
  methods: {
    onInputSelected(option) {
      if (this.selected && this.selected.value && option && option.value && this.selected.value === option.value) {
        this.open = false
      } else {
        this.selected = option
        this.open = false
        this.$emit('input-selected', option)
      }
    },
    selectDefaultDevice() {
      this.selected = this.default ? this.default : this.options.length > 0 ? this.options[0] : null
    },
    onClickSelect() {
      this.open = !this.open
      this.$emit('input-click', this.selected)
    },
    onBlur() {
      this.open = false
      this.$emit('input-blur', this.selected)
    }
  }
}
</script>
<style scoped>
.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 30px;
  line-height: 30px;
  z-index: 999;
}

.custom-select .selected {
  color: rgba(var(--vs-secondary), 1) !important;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  padding-left: 1em;
  padding-right: 2em;
  cursor: pointer;
  user-select: none;
  background: #e6ecf1;
  font-weight: 600;
}

.custom-select .selected.open {
  border-radius: 13px 13px 0px 0px;
}

.custom-select .selected:after {
  position: absolute;
  content: '';
  top: 14px;
  right: 0.5em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: rgba(var(--vs-secondary), 1) transparent transparent transparent !important ;
}

.custom-select .items {
  color: #fff;
  overflow: hidden;
  position: absolute;
  background-color: #e6ecf1;
  left: 0;
  right: 0;
  z-index: 1;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.custom-select .items div {
  color: rgba(var(--vs-secondary), 1);
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div:hover {
  color: #fff;
  background-color: rgba(var(--vs-secondary), 1);
}

.selectHide {
  display: none;
}
</style>
